import Event from './Event.js';

const EventSearchResults = ({filteredEvents}) => {
	if (filteredEvents.length) {
		return filteredEvents.map(event => <Event key={event.ID} event={event}/>);
	}

	return (
		<p>No matching events found.</p>
	)
}

export default EventSearchResults;
