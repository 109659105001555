const DateFilters = (props) => {
	return (
		<>
			<label htmlFor="date" className="screen-reader-text">Filter events by category</label>
			<select value={props.activeFilters} name="date" className="date-filters" onChange={props.onChange}>
				<option value=''>Date</option>
				{
					props.filterOptions.map(date => {
						return (
							<option value={date.value} key={date.value}>
								{date.label}
							</option>
						)
					})
				}
			</select>
		</>
	)
}

export default DateFilters;
