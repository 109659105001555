import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

const eventListContainer = document.getElementById('naca_event_list');
if (eventListContainer) {
	ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	eventListContainer
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
