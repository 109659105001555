const CategoryFilters = (props) => {
	return (
		<>
			<label htmlFor="category" className="screen-reader-text">Filter events by category</label>
			<select value={props.activeFilter} name="category" className="category-filters" onChange={props.onChange}>
				<option value=''>Category</option>
				{
					props.filterOptions.map(category => {
						return (
							<option value={category} key={category}>
								{category}
							</option>
						)
					})
				}
			</select>
		</>
	)
}

export default CategoryFilters;
