import React, { Component } from 'react';
import CategoryFilters from './components/filters/CategoryFilters';
import DateFilters from './components/filters/DateFilters';
import EventSearchResults from './components/event/EventSearchResults';
import StateFilters from './components/filters/StateFilters';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterOptions: {
				dates: [],
				states: [],
				categories: [],
			},
			activeFilters: {
				category: '',
				date: '',
				keyword: '',
				onlineEvents: '',
				eventState: '',
			},
			numEventsToShow: 8,
			events: [],
			filteredEvents: [],
			pagedEvents: [],
		};

		this.applyEventFilters = this.applyEventFilters.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.loadMoreEvents = this.loadMoreEvents.bind(this);
	}

	applyEventFilters() {
		let filteredEvents = [...this.state.events];
		const {
			category,
			date,
			keyword,
			eventState,
			onlineEvents
		} = this.state.activeFilters;

		if (category) {
			filteredEvents = this.filterEventsByCategory(category, filteredEvents);
		}

		if (keyword) {
			filteredEvents = this.filterEventsByKeyword(keyword, filteredEvents);
		}

		if (eventState) {
			filteredEvents = this.filterEventsByState(eventState, filteredEvents);
		}

		if (onlineEvents) {
			filteredEvents = this.filterForOnlineEvents(filteredEvents);
		}

		if (date) {
			filteredEvents = this.filterEventsByDate(date, filteredEvents);
		}

		this.setState(() => ({
			filteredEvents,
			pagedEvents: filteredEvents.slice(0, this.state.numEventsToShow)
		}));
	}

	filterEventsByDate(date, events) {
		const dateObject = new Date(date), y = dateObject.getFullYear(), m = dateObject.getMonth();
		const filterRangeStart = new Date(y, m, 1);
		const filterRangeEnd = new Date(y, m + 1, 0, 23, 59, 59);
		return events.filter(event => {
			const eventStartDate = new Date(event.date.start.utc_time);
			const eventEndDate = new Date(event.date.end.utc_time);
			const validStart = eventStartDate.getTime() > filterRangeStart.getTime();
			const validEnd = eventEndDate.getTime() < filterRangeEnd.getTime();
			return  validStart && validEnd;
		})
	}

	filterEventsByCategory(category, events) {
		return events.filter(event => {
			return event.categories.some(event_category => {
				return event_category.name === category;
			})
		})
	}

	filterForOnlineEvents(events) {
		return events.filter(event => event.online_event);
	}

	filterEventsByKeyword(keyword, events) {
		return events.filter(event => {
			return event.name.toLowerCase().includes(keyword) || event.preview.toLowerCase().includes(keyword);
		})
	}

	filterEventsByState(state, events) {
		return events.filter(event => event.event_state === state);
	}

	handleSubmit(event) {
		event.preventDefault();
	}

	getEvents() {
		return fetch('/wp-json/naca/v1/events')
			.then(response => {
				return response.json();
			})
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.filterPropDidChange({...this.state}, prevState)) {
			this.applyEventFilters();
		}
	}

	filterPropDidChange(snapshot, prevState) {
		const filterProps = [];
		for (let [key] of Object.entries(snapshot.activeFilters)) {
			filterProps.push(key);
		}

		return filterProps.some(filter => {
			return snapshot.activeFilters[filter] !== prevState.activeFilters[filter];
		})
	}

	clearFilters() {
		this.setState((state) => {
			let clearedFilters = {...state.activeFilters};
			for (let [key] of Object.entries(clearedFilters)) {
				clearedFilters[key] = '';
			}
			return {
				activeFilters: clearedFilters
			}
		});
	}

	componentDidMount() {
		this.getEvents().then(response => {
			let cache = {
				categories: {},
				eventStates: {}
			}
			let categories = [];
			let eventStates = [];

			response.results.forEach(event => {
				event.categories.forEach(category => {
					if (!cache.categories.hasOwnProperty(category.slug)) {
						categories.push(category.name);
						cache.categories[category.slug] = null;
					}
				})

				if (event.event_state && !cache.eventStates.hasOwnProperty(event.event_state)) {
					eventStates.push(event.event_state);
					cache.eventStates[event.event_state] = null;
				}
			})

			categories.sort();

			this.setState((state) => {
				return {
					events: response.results,
					filteredEvents: response.results,
					pagedEvents: response.results.slice(0, state.numEventsToShow),
					filterOptions: {
						dates: this.getDateOptions(),
						categories: categories,
						states: eventStates,
					}
				}
			})
		})
	}

	loadMoreEvents() {
		this.setState((state) => {
			const events = [...state.pagedEvents];
			const sliceStart = state.pagedEvents.length;
			const sliceEnd = sliceStart + state.numEventsToShow;
			const nextEventsSet = state.filteredEvents.slice(sliceStart, sliceEnd);
			return {
				pagedEvents: events.concat(nextEventsSet)
			}
		})
	}

	addMonthsToDate(months) {
		const date = new Date()
		return new Date(date.setMonth(date.getMonth() + months));
	}

	getDateOptions() {
		let options = new Set();
		let numberOfMonths = 6;
		let monthIterator = 0;
		const startingDate = new Date();

		for (let i = 0; i < numberOfMonths; i++) {
			let dateOption = new Date(startingDate.getFullYear(), startingDate.getMonth() + monthIterator)
			const monthName = dateOption.toLocaleString('default', { month: 'long' })
			const year = dateOption.getFullYear();
			const optionKey = year + '-' + (dateOption.getMonth() +1);
			const optionDisplayValue = year + ' - ' + monthName;

			options.add({
				value: optionKey,
				label: optionDisplayValue,
			})

			monthIterator++;
		}
		return [...options];
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newActiveFilters = {...this.state.activeFilters};
		newActiveFilters[name] = value;
		this.setState(() => ({
			activeFilters: newActiveFilters
		}));
	  }

	render() {
		const {
			keyword,
			onlineEvents,
		} = this.state.activeFilters;

		return (
			<div className="App">
				<form className="event-filters" onSubmit={this.handleSubmit}>
					<label htmlFor="keyword" className="screen-reader-text">Search by keywords</label>
					<input value={keyword} name="keyword" type="text" className="keyword-filter" placeholder="Search by keywords" onChange={this.handleInputChange} />

					<CategoryFilters
						activeFilter={this.state.activeFilters.category}
						filterOptions={this.state.filterOptions.categories}
						onChange={this.handleInputChange}
					/>

					<DateFilters
						activeFilter={this.state.activeFilters.date}
						filterOptions={this.state.filterOptions.dates}
						onChange={this.handleInputChange}
					/>

					<StateFilters
						activeFilter={this.state.activeFilters.eventState}
						filterOptions={this.state.filterOptions.states}
						onChange={this.handleInputChange}
					/>

					<div className="online-events">
						<input  checked={onlineEvents} type="checkbox" name="onlineEvents" className="online-events-filter" onChange={this.handleInputChange}/>
						Online Events
					</div>

					<button className="filter-reset button btn-solid-red" onClick={this.clearFilters}>Clear filters</button>
				</form>

				<EventSearchResults filteredEvents={this.state.pagedEvents} />

				{this.state.pagedEvents.length < this.state.filteredEvents.length &&
					<footer className="load-more-container">
						<button className="load-more button btn-large btn-solid-red" onClick={this.loadMoreEvents}>Load more events</button>
					</footer>
				}
			</div>
		)
	}
}

export default App;
