const stripLeadingZero = ( string ) => {
	return Number.parseInt(string[0]) === 0 ? string.substring(1) : string;
}

const getTimeInHours = time => {
	return time.getTime() / 1000 / 60;
}

const getDurationInMinutes = (startTime, endTime) => {
	return Math.abs(getTimeInHours(endTime) - getTimeInHours(startTime))
}

const Event = ({event}) => {
	const startDate = new Date(event.date.start.utc_time);
	const endDate = new Date(event.date.end.utc_time);
	const durationInMinutes = getDurationInMinutes(startDate, endDate);
	const durationText = event.all_day_event ? 'All day event' : durationInMinutes + ' mins';

	let recurringFrequencyText = '';
	if (event.recurrence) {
		switch (event.recurrence.interval) {
			case '2':
				recurringFrequencyText = 'other';
				break;
			case '3':
				recurringFrequencyText = 'third';
				break;
			default:
				recurringFrequencyText = '';
				break;
		}
	}

	const registerLinkText = event.member_event ? 'Member register' : 'Register';
	let eventIconClass = 'location-icon ';
	eventIconClass += event.online_event ? 'webinar' : 'map-marker';
	const eventId = `event_${event.ID}`;

	return (
		<div id={eventId} className="event-preview" key={event.ID }>
			<div className="event-date">
				{event.recurring && event.recurrence.frequency === 'weekly' &&
					<div className="recurring-details">
						Every {recurringFrequencyText} {new Intl.DateTimeFormat('en-US', { weekday: 'long'}).format(startDate)}
					</div>
				}
				<div className="event-vert">
					<div className="month-and-day">
						{event.date.start.month} {event.date.start.date}, {event.date.start.year}
					</div>
					<div className="start-time">
						{stripLeadingZero(event.date.start.time)} to				</div>
					<div className="end-time">
						{stripLeadingZero(event.date.end.time)}  {event.date.time_zone}				</div>
				</div>
			</div>

			<div className="event-main">

				<div className="event-categories">
					{
						event.categories.map(category => <span key={category.term_id} className="event-category">{category.name}</span>)
					}
				</div>

				<h1>{event.name}</h1>

				{durationInMinutes > 0 &&
					<p className="event-duration">
						{durationText}
					</p>
				}

				<div className="event-excerpt">
					{event.preview}
				</div>

				<div className="event-location">
					<span className={eventIconClass}></span>{event.location}
				</div>

			</div>

			<div className="register-link-container">
				<a href={event.register_url} target="_blank" rel="noreferrer">{registerLinkText}</a>
			</div>
		</div>
	)
}

export default Event;
