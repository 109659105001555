const StateFilters = (props) => {
	return (
		<>
			<label htmlFor="eventState" className="screen-reader-text">Filter events by state</label>
			<select value={props.activeFilter} name="eventState" className="state-filters" onChange={props.onChange}>
				<option value=''>State</option>
				{
					props.filterOptions.map(state => <option value={state} key={state}>{state}</option>)

				}
			</select>
		</>
	)
}

export default StateFilters;
